import "./ModelForm.css";
import { ModelEntry } from "../../model/ModelEntry";
import { Region } from "../../model/Region";
import { DetectionSystem } from "../../model/DetectionSystem";
import { IoClose } from "react-icons/io5";
import { useEffect, useState } from "react";
import { StageConfig } from "../../config/StageConfig";
import sentryFetch from "@amzn/sentry-fetch";
import { RequestType } from "../../model/RequestType";
import { Snackbar } from "@mui/material";
import { isJSONResponse } from "../../config/Requests";

/**
 * @author stcatai Apr 1, 2024
 */

interface ModelFormProps {
  model?: ModelEntry;
  requestType: RequestType;
  setShowModelForm: (show: boolean) => void;
}

export function ModelForm({model, requestType, setShowModelForm}: ModelFormProps) {
  const [stageConfig, setStageConfig] = useState<StageConfig>();
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>("");

  const [region, setRegion] = useState<Region>(model?.region || Region.NA);
  const [modelName, setModelName] = useState<string | undefined>(model?.modelName);
  const [detectionSystem, setDetectionSystem] = useState<DetectionSystem>(model?.detectionSystem || DetectionSystem.Offline);
  const [publishable, setPublishable] = useState<boolean | undefined>(model?.publishable);
  const [labels, setLabels] = useState<Set<string> | undefined>(model?.labels);
  const [ttl, setTtl] = useState<number | undefined>(model?.ttl);
  const [lastCommitDescription, setLastCommitDescription] = useState<string | undefined>(model?.lastCommitDescription);
  const [updatedCommit, setUpdatedCommit] = useState<boolean>(false);

  useEffect(() => {
    fetch("../stageConfig.json")
      .then(res => res.json())
      .then(stageConfig => setStageConfig(stageConfig));
  }, []);

  const submitRequest = () => {
    if (!stageConfig) return;

    setUpdatedCommit(false);
    const splitLabels = Array.from(labels || [], label => label.trim())
                                    .filter(label => label !== '')
                                    .join(",");

    sentryFetch(`${stageConfig.url}/modelsManager?requestType=${requestType}`+
      `&region=${region || ""}&detectionSystem=${detectionSystem || ""}&publishable=${publishable || "false"}`+
      `&ttl=${ttl || ""}&labels=[${encodeURIComponent(splitLabels)}]&modelName=${encodeURIComponent(modelName || "")}`+
      `&lastCommitDescription=${encodeURIComponent(lastCommitDescription || "")}`, {
      method: "GET",
      credentials: "include",
      sentryOptions: {
        followMidwayStepUp: true
      }
    })
      .then(response => {
        if (response.ok) {
          return response.text().then(response => {
            if (isJSONResponse(response)) {
              return JSON.parse(response).message;
            } else {
              return response;
            }
          });
        } else {
          return response.text().then(response => {
            if (isJSONResponse(response)) {
              throw new Error(JSON.parse(response).message);
            } else {
              throw new Error(response);
            }
          });
        }
      })
      .then(response => {
        console.log(response);
        setShowModelForm(false);
      })
      .catch(error => {
        console.error(error.message);
        setResponseMessage(error.message);
        setShowMessage(true);
      });
  };

  const getFormTitle = (requestType: RequestType) => {
    switch (requestType) {
      case RequestType.ADD_MODEL:
        return "Add model entry";
      case RequestType.UPDATE_MODEL:
        return "Update model entry";
      case RequestType.REMOVE_MODEL:
        return "Remove model entry";
      default:
        return "";
    }
  }

  const numberInputValidator = (event: { key: string; preventDefault: () => void; })  => {
    if (!/^[0-9]$/.test(event.key) && event.key !== 'Backspace') {
      event.preventDefault();
    }
  }

  return <>
    <div className="model-form-header">
      <div/>
      <p className="text-center">{getFormTitle(requestType)}</p>
      <IoClose className="x-button" onClick={() => setShowModelForm(false)}/>
    </div>
    <div className="model-form">
      <div className="model-form-field">
        <label htmlFor="region" className={requestType === RequestType.UPDATE_MODEL || requestType === RequestType.REMOVE_MODEL ? "text-secondary" : ""}>Region</label>
        <select disabled={requestType === RequestType.UPDATE_MODEL || requestType === RequestType.REMOVE_MODEL} id="region" defaultValue={model?.region} onChange={(e) => setRegion(e.target.value as Region)}>
          {Object.values(Region).map((region, idx) =>
            <option value={region} key={idx}>{region}</option>
          )}
        </select>
      </div>
      <div className="model-form-field">
        <label htmlFor="detection-system" className={requestType === RequestType.UPDATE_MODEL || requestType === RequestType.REMOVE_MODEL ? "text-secondary" : ""}>Detection System</label>
        <select disabled={requestType === RequestType.UPDATE_MODEL || requestType === RequestType.REMOVE_MODEL} id="detection-system" defaultValue={model?.detectionSystem} onChange={(e) => setDetectionSystem(e.target.value as DetectionSystem)}>
          {Object.values(DetectionSystem).map((detectionSystem, idx) =>
            <option value={detectionSystem} key={idx}>{detectionSystem}</option>
          )}
        </select>
      </div>
      <div className="model-form-field">
        <label htmlFor="model-name" className={requestType === RequestType.UPDATE_MODEL || requestType === RequestType.REMOVE_MODEL? "text-secondary" : ""}>Model name</label>
        <input type="text" disabled={requestType === RequestType.UPDATE_MODEL || requestType === RequestType.REMOVE_MODEL} id="model-name" defaultValue={model?.modelName} onChange={(e) => setModelName(e.target.value)}/>
      </div>
      <div className="model-form-field">
        <label htmlFor="publishable" className={requestType === RequestType.REMOVE_MODEL ? "text-secondary" : ""}>Publishable</label>
        <input type="checkbox" className="checkbox" disabled={requestType === RequestType.REMOVE_MODEL} id="publishable" defaultChecked={model?.publishable} onChange={(e) => setPublishable(e.target.checked)}/>
      </div>
      <div className="model-form-field">
        <label htmlFor="ttl" className={requestType === RequestType.REMOVE_MODEL ? "text-secondary" : ""}>TTL (mins)</label>
        <input type="number" inputMode="numeric" onKeyDown={numberInputValidator} disabled={requestType === RequestType.REMOVE_MODEL} id="ttl" defaultValue={model?.ttl} onChange={(e) => setTtl(Number.parseInt(e.target.value))}/>
      </div>
      <div className="model-form-field">
        <label htmlFor="labels" className={requestType === RequestType.REMOVE_MODEL ? "text-secondary" : ""}>Labels</label>
        <input type="text" disabled={requestType === RequestType.REMOVE_MODEL} id="labels" defaultValue={model && model.labels ? Array.from(model.labels).join(",") : ""} onChange={(e) => setLabels(new Set(e.target.value.split(",").filter(label => label.length > 0)))}/>
      </div>
      <div className="model-form-field">
        <label htmlFor="commit-description">Commit description <span className="text-danger">*</span></label>
        <input type="text" id="commit-description" required={true} onChange={(e) => {setLastCommitDescription(e.target.value); setUpdatedCommit(true); }}/>
      </div>
      <div className="model-form-field">
        <label>Confirm?</label>
        <button onClick={submitRequest} disabled={!updatedCommit || lastCommitDescription === ''}>Yes</button>
        <button onClick={() => setShowModelForm(false)}>No</button>
      </div>

      <Snackbar
        open={showMessage}
        autoHideDuration={5000}
        onClose={() => {setShowMessage(false); setUpdatedCommit(false)}}
        message={responseMessage}
        anchorOrigin={{vertical: "bottom", horizontal: "center"}}
      />
    </div>
  </>
}